<template>

	<div>

		<!-- Filters -->
		<students-list-filters
			:options="options"
			:contracting-companies-filter.sync="contractingCompaniesFilter"
        />

		<!-- Add Or Edit Siderbar Modal -->
		<students-list-add-and-edit
            :is-sidebar-active.sync="isSidebarActive"
            :data="studentData"
            :mode="mode"
            @refresh-data="refetchData"
        />

		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0">

			<div class="m-2">

				<!-- Table Top -->
				<b-row>

					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>

					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="ml-auto">
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar personal ..."
								trim
							/>
							<b-button
                                variant="primary"
                                class="ml-auto"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                @click="showSidebar('add')"
                            >
								<span class="text-nowrap">Agregar Personal</span>
							</b-button>
						</div>
					</b-col>

				</b-row>

			</div>

			<b-table
				ref="refStudentListTable"
				class="position-relative table-students"
				:style="totalStudents === 1 ? 'height: 200px' : 'height: inherit'"
				:items="fetchStudents"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>

				<template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mb-1"></b-spinner>
                        <div><b>CARGANDO</b></div>
                    </div>
                </template>

				<template #head(inscriptions_count)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(student)="data">
					<b-media vertical-align="center">
                        <template #aside>
                            <b-avatar
                                size="32"
                                :src="null"
                                :text="avatarText(data.item.fullName)"
                                variant="light-success"
                            />
                        </template>
                        <b-link class="font-weight-bold d-block text-nowrap">
                            {{ data.item.fullName }}
                        </b-link>
                    </b-media>
				</template>

				<template #cell(user_email)="data">
					<span class="d-block text-nowrap">
						{{ data.item.user_email }}
					</span>
				</template>

				<template #cell(phone)="data">
					<span class="d-block text-nowrap">
						{{ data.item.phone ? data.item.phone : '-' }}
					</span>
				</template>

				<template #cell(createdAt)="data">
                    <span class="d-block text-nowrap">
						{{ moment(data.item.createdAt).format('DD/MM/YYYY HH:mm:ss') }}
					</span>
				</template>

				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL">

						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item @click="showSidebar('update', data.item)">
							<feather-icon icon="EditIcon" />
							<span class="align-middle ml-50">Editar</span>
						</b-dropdown-item>

						<b-dropdown-item @click="deleteStudent(data.item.id)">
							<feather-icon icon="TrashIcon" />
							<span class="align-middle ml-50">Eliminar</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>

			</b-table>

			<div class="mx-2 mb-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end">

						<b-pagination
							v-model="currentPage"
							:total-rows="totalStudents"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item">

							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>

	</div>

</template>

<script>

	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import { ref, onBeforeMount, onUnmounted } from '@vue/composition-api';
	import StudentsListAddAndEdit from './StudentsListAddAndEdit';
	import StudentsListFilters from './StudentsListFilters.vue';
	import { useToast } from 'vue-toastification/composition';
	import studentStoreModule from '../studentStoreModule';
	import useStudentsList from './useStudentsList';
	import { avatarText } from '@core/utils/filter';
	import VueSweetalert2 from 'vue-sweetalert2';
	import Ripple from 'vue-ripple-directive';
	import vSelect from 'vue-select';
	import store from '@/store';
	import moment from 'moment';
	import axios from '@axios';
	import Vue from 'vue';
	import 'animate.css';

	Vue.use(VueSweetalert2);

	export default {
		components: {
			StudentsListAddAndEdit,
			StudentsListFilters,

			vSelect
		},
		directives: {
            Ripple
        },
		data() {
            return {
                moment
            }
        },
		setup() {

			// USE TOAST
			const toast = useToast();

			// REFS
			const isSidebarActive = ref(false);
			const studentData = ref({});
			const options = ref({});
			const mode = ref('add');

			const STUDENT_APP_STORE_MODULE_NAME = 'app-student';

			// REGISTER MODULE
			if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) store.unregisterModule(STUDENT_APP_STORE_MODULE_NAME);
			});

			// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( async () => {
				await getSelectFilters();
            });

            const getSelectFilters = async () => {
                const { data } = await axios.get('/selects/contracting-companies');
                options.value = data;
            };

			const showSidebar = (action, data = null) => {
                mode.value = action;

                studentData.value = {
                    id: data ? data.id : null,
                    names: data ? data.user_names : null,
					surnames: data ? data.user_surnames : null,
                    documentNumber: data ? data.document_number : null,
                    email: data ? data.user_email : null,
					phone: data ? data.phone : null,
					contracting_company: data ? { value: data.contracting_company_id, label: data.contracting_company_name } : null
                };

                isSidebarActive.value = true
            };

			const deleteStudent = (id) => {

				Vue.swal({
					title: '¿Está seguro(a) de eliminar al personal?',
					text: '¡Si no lo está, puede cancelar la acción!',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, eliminar!',
					cancelButtonText: 'Cancelar',
					customClass: {
						confirmButton: 'btn btn-primary',
						cancelButton: 'btn btn-outline-danger ml-1'
					},
					showClass: {
						popup: 'animate__animated animate__tada'
					},
					buttonsStyling: false
				}).then(result => {

					if (result.value)
					{
						store.dispatch('app-student/deleteStudent', { id })
							.then( response => {

								refetchData();

								toast({
									component: ToastificationContent,
									props: {
										title: response.data.message,
										icon: 'CheckIcon',
										variant: 'success'
									}
								});

							})
							.catch( () => {

								toast({
									component: ToastificationContent,
									props: {
										title: 'Error al eliminar al personal',
										icon: 'AlertTriangleIcon',
										variant: 'danger'
									}
								});

							});
					}

				});

			};

			const {
				fetchStudents,
				tableColumns,
				perPage,
				currentPage,
				totalStudents,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refStudentListTable,
				refetchData,

				// EXTRA FILTERS
                contractingCompaniesFilter
			} = useStudentsList();

			return {
				fetchStudents,
				tableColumns,
				perPage,
				currentPage,
				totalStudents,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refStudentListTable,
				refetchData,

				// REFS
				isSidebarActive,
				studentData,
				options,
				mode,

				// EXTRA FILTERS
                contractingCompaniesFilter,

				// ACTIONS
				avatarText,
				showSidebar,
				deleteStudent
			}
		}
	}

</script>

<style lang="scss" scoped>

	.media {
        align-items: center;
    }

	.per-page-selector {
		width: 90px;
	}

</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
	@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
